import Slider from "react-slick";
import './whenUse.css';

import vehiculo from '../../assets2/whenUse/vehiculos.svg'
import usuario from '../../assets2/whenUse/control-usuario.svg'
import control from '../../assets2/whenUse/control.svg'
import seguridad from '../../assets2/whenUse/seguridad.svg'

import centroEducativo from '../../assets2/whenUse/universidad.webp'
import educacion from '../../assets2/whenUse/educacion.png'

import eventos from '../../assets2/whenUse/eventos.webp'
import evento from '../../assets2/whenUse/evento.png'

import centroMedico from '../../assets2/whenUse/centro-medico.webp'
import medicina from '../../assets2/whenUse/medicina.png'

import controlVehicular from '../../assets2/whenUse/control-vehiculo.webp'
import parking from '../../assets2/whenUse/parking.png'

import empresas from '../../assets2/whenUse/control-oficina.webp'
import oficinas from '../../assets2/whenUse/oficina.png'

import suburbios from '../../assets2/whenUse/urbanizacion.webp'
import casas from '../../assets2/whenUse/ciudadela.png'

import { useLanguage } from '../../contexts/LanguageContext';

export const WhenUse = () => {

    const { language, translations } = useLanguage();

    const createSection = (categoryKey, image) => ({
        titulo: translations[language].functions.donde[categoryKey].titulo,
        descripcion: translations[language].functions.donde[categoryKey].descripcion,
        lista: (
            <ul>
                {Array.from({ length: 4 }, (_, i) => 
                    <li><p>{translations[language].functions.donde[categoryKey][i + 1]}</p></li>
                )}
            </ul>
        ),
        imagen: image,
    });
    
    const donde = [
        createSection('educacion', centroEducativo),
        createSection('evento', eventos),
        createSection('medicina', centroMedico),
        createSection('vehiculo', controlVehicular),
        createSection('empresa', empresas),
        createSection('urbano', suburbios),
    ];
    
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="when-use">
            <Slider  {...settings}>
                {donde.map((lugar, index) => (
                    <div>
                        <h1>DONDE USAR PINLET</h1>
                        <div className="contenedor">
                            <div className="container-text">
                                <h2>{lugar.titulo}</h2>
                                <p>{lugar.descripcion}</p>
                                {lugar.lista}
                            </div>
                            <div className="container-img">
                                <div className="card-donde">
                                    <img className="main-img" src={lugar.imagen} alt={lugar.titulo.toLowerCase()} loading="lazy"></img>
                                </div>
                                <div className="cualidades">
                                    <img src={control} alt="icono de control de acceso" loading="lazy"></img>
                                    <img src={usuario} alt="icono de usuario seguro" loading="lazy"></img>
                                    <img src={seguridad} alt="icono de seguridad" loading="lazy"></img>
                                    <img src={vehiculo} alt="icono de entrada vehicular" loading="lazy"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}